$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;
// Large desktops
$screen-xxl-min: 1400px;
// Extra Large tablets and desktops
$screen-xxxl-min: 1700px;

// Small devices
@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

// Extra Extra large devices
@mixin xxl {
  @media (min-width: #{$screen-xxl-min}) {
    @content;
  }
}

// Extra Extra Extra large devices
@mixin xxxl {
  @media (min-width: #{$screen-xxxl-min}) {
    @content;
  }
}

.promotion-details-stakes {
  .overlay {
    width: fit-content;
  }
}

.deposit-cards {
}
.deposit-section-img {
  width: 250px;
  position: relative;
  .img-response {
    width: 200px;
  }
}
.width-for-modal {
  width: 100%;
}
[data-reach-dialog-content] {
  width: auto;
  max-width: 300px;

  @include lg {
    max-width: 60vw;
  }
  @include xxl {
    max-width: 1200px;
  }
}
