@media only screen and (min-width: 300px) and (max-width: 768px) {
  p {
    word-spacing: 0.125rem;
    font-size: 0.9375rem;
    line-height: 1.625rem;
  }
  body {
    overflow-x: hidden;
  }
  .img-response {
    width: 100%;
  }

  .openbtn {
    margin-right: 0.9375rem;
  }
  .star {
    margin-left: 0rem;
  }
  .banner_section h1 {
    font-size: 2.5rem;
    line-height: 3.125rem;
  }

  .ban-inner {
    margin-top: 2.5rem;
    padding-left: 1.25rem;
  }

  .ban-inner p {
    width: 100%;
  }

  .ban-img {
    margin-left: 0px;
  }

  .mobile {
    display: block;
  }
  .website {
    display: none;
  }

  .ban-form {
    margin-top: 0px;
  }
  .ban-top {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .banner_img h1 {
    width: 300px;
    padding: 30px;
    padding-bottom: 0px;
    margin-top: 50px;
    font-size: 30px;
    line-height: 37px;
  }
  .banner_img p {
    width: 296px;
    line-height: 20px;
    font-size: 14px;
    padding: 0px 30px 0px 30px;
  }
  .form {
    width: 100%;
    margin-top: 90px;
    float: none;
  }
  .banner_img button {
    padding: 5px 18px;
    margin-left: 30px;
    font-size: 16px;
  }
  .lineulta {
    margin-top: -43px;
    margin-right: -14px;
  }
  .boxes {
    width: 350px;
  }
  .back-blue {
    width: 350px;
    padding: 11px 22px 11px 22px;
    margin-left: 12px;
    border-radius: 5px;
    font-size: 16px;
  }
  .service-section h3 {
    padding-bottom: 0px;
  }
  .service-tab p {
    line-height: 17px;
  }
  .bt {
    margin-top: 20px;
  }
  .mt-20 {
    margin-top: 20px;
  }
  .app-small img {
    width: 75px;
    margin-left: 0px;
    margin-top: 20px;
    padding-left: 0px;
  }
  .footer-section li img {
    width: 100px;
  }
  .media img {
    margin: 5px;
    width: 30px;
    height: 30px;
  }
  .ser-num1 {
    left: 50%;
  }
  .ser-num3 {
    left: 50%;
  }
  .title-grey {
    font-size: 18px;
    text-align: center;
  }
  .title-before {
    font-size: 20px;
    text-align: center;
  }
  h1.title-before:before {
    left: 12%;
    margin-top: 7px;
  }
  .mon {
    display: flex;
  }
  .mon p {
    text-align: left;
    padding-left: 19px;
  }
  .fir img {
    margin-top: 20px;
    width: 40px;
  }
  .noone::before {
    top: 103px;
  }
  .noone::after {
    top: 106px;
  }
  .fir1 {
    margin-left: 6px;
  }
  .noone::after {
    width: 40px;
    height: 40px;
    left: 53px;
    top: 106px;
  }
  .noone::before {
    padding: 10px 19px;
    left: -13px;
  }
  .orange-title {
    padding: 15px 25px 15px 25px;
    border-radius: 10px;
    font-size: 16px;
  }
  .newbox {
    display: flex;
    margin-bottom: 15px;
  }
  .bg-blue {
    background: #00bca6;
    display: inline-block;
    border-radius: 55%;
    position: absolute;
    top: 53px;
    left: 85%;
  }
  .bgwhite {
    border-radius: 15px;
  }
  .bg-in {
    padding-right: 50px;
  }
  .bg-in1 {
    padding-left: 50px;
  }
  .userimg {
    width: 70px;
    left: 36%;
  }
  .timerimg {
    width: 70px;
    left: 36%;
  }
  .calcimg {
    width: 70px;
    left: 36%;
  }
  .title-works::before {
    font-size: 70px;
    left: 73px;
    top: -73px;
  }
  .last-divv {
    padding: 0px;
    padding-bottom: 30px;
    margin-left: 0px;
  }
  .k-3 {
    margin-left: -15px;
  }
  .mt-r {
    left: 0%;
  }
  .work-section p {
    padding: 0px 0px;
    word-spacing: 0px;
  }
  .title-works3::before {
    content: "2";
    left: 73px;
    font-size: 70px;
    top: -90px;
  }
  .title-works2::before {
    content: "3";
    font-size: 70px;
    left: 73px;
    top: -73px;
  }
  .title-works4::before {
    content: "4";
    font-size: 70px;
    top: -73px;
    left: 73px;
  }

  .title-works4::after {
    content: "";
    content: none;
    /* background: url(./assets/images/43.png); */
    display: inline-block;
    width: 90%;
    height: 188px;
    background-repeat: no-repeat;
    position: absolute;
    top: -136px;
    right: -21px;
    background-size: contain;
  }
  .div4 {
    position: relative;
    left: 0px;
    top: 15px;
  }
  .title-works3::after {
    content: "";
    content: none;
    /* background: url(./assets/images/12.png); */
    display: inline-block;
    width: 100%;
    height: 187px;
    background-repeat: no-repeat;
    position: absolute;
    top: -136px;
    left: 0px;
  }
  .msgimg {
    width: 70px;
    left: 36%;
  }
  .title-works {
    text-align: center;
    left: 0%;
  }
  .title-works2 {
    text-align: center;
    left: 0%;
  }
  .title-works4 {
    text-align: center;
    left: 0%;
  }
  .title-works3 {
    text-align: center;
    left: 0%;
  }
  .mt-25 {
    margin-top: 35px;
  }
  .lg-1 {
    margin-left: 0px;
  }
  .img-2 {
    margin-top: 30px;
    margin-left: 0px;
  }
  .work-inner2 {
    margin-top: 0px;
  }
  .img-3 {
    margin-top: 20px;
    margin-left: 0px;
  }
  .work-inner1 {
    margin-top: 30px;
  }
  .work-inner3 {
    margin-top: 40px;
    margin-left: 0px;
    text-align: center;
  }

  .img-4 {
    margin-top: 30px;
    margin-left: 0px;
  }

  .work-inner4 {
    margin-top: 30px;
    margin-left: 0px;
    text-align: center;
  }
  .work-inner {
    margin-top: 40px;
  }

  .work-section h3 {
    padding-bottom: 0px;
  }

  /* Landing page */

  .lan-ser-btn {
    margin-top: 0px;
    background: #ffffff;
    color: #514e81;
    border: none;
    font-weight: bold;
    padding: 22px 50px;
    font-size: 22px;
    box-shadow: 0px 10px 7px rgb(0 0 0 / 10%);
    border-radius: 0px 0px 14px 14px;
  }

  .mt-50 {
    margin-top: 50px;
  }

  .land-tab {
    margin-top: 50px;
  }

  .mt-150 {
    margin-top: 150px;
  }

  .learn-btn:after {
    display: none;
  }
  .learn-btn:before {
    display: none;
  }
  .learn-btn1:after {
    display: none;
  }
  .learn-btn1:before {
    display: none;
  }
  .btn4 {
    margin-bottom: 20px;
  }
  .tut-inner {
    margin-top: 0px;
    text-align: center;
  }
  .tut-inner1 {
    margin-top: 0px;
    text-align: center;
  }
  .tut-inner3 {
    margin-top: 0px;
    text-align: center;
  }
  .learn-btn1 {
    padding: 15px 80px;
  }
  .btn2 {
    padding: 15px 20px !important;
  }
  .p-10 {
    padding: 10px;
  }
  .last-t {
    margin-left: 0px;
    padding-top: 20px;
  }
  .last-h3-pro {
    padding-bottom: 10px;
    font-size: 32px;
  }

  .calander-inner {
    background: #fff;
    width: 100%;
  }
  .testimonial {
    padding: 33px 0px;
  }
  .teach-btn {
    padding: 6px 26px;
    font-size: 16px;
  }
  .live-section-tut {
    margin-bottom: 40px;
  }
  .live-head h2 {
    margin-top: 20px;
    font-size: 29px;
    margin-left: 0px;
  }
  .live-head {
    text-align: center;
  }
  .tution-p {
    padding-right: 0px;
    text-align: center;
  }
  .tution-img {
    margin-left: 0px;
    margin-top: 10px;
  }
  .ttt {
    text-align: center;
  }
  .footer-img img {
    margin-top: 15%;
    margin-left: 20%;
    margin-bottom: 30px;
  }
  .teach-sec-btn {
    padding: 7px 12px !important;
  }
  .teach-head {
    text-align: center;
  }
  .teach-head h5 {
    font-size: 1.875rem;
  }
  .teach-main-btn {
    font-size: 1rem !important;
    padding: 0.4375rem 5.375rem !important;
  }
  .cal-btn {
    font-size: 1rem !important;
    margin-top: 1.875rem !important;
    padding: 16px 3px !important;
    padding-bottom: 4.375rem !important;
  }
  .nh {
    background: #f8f8f8 !important;
  }
  .ban-img-teach {
    margin-top: 1.875rem;
    margin-left: 0rem;
  }
  .main-head h2 {
    font-size: 30px;
  }

  .footer-section h3 {
    font-size: 14px;

    text-transform: capitalize;
  }
  .footer-section ul li a {
    font-size: 12px;
  }
}

@media only screen and (min-width: 767px) and (max-width: 991px) {
  .app-small img {
    justify-content: center;
    max-width: 7.5rem;
    margin-left: 0rem;

    padding-left: 0rem;
  }
}
