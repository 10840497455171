.promotion-details-stakes {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 30px;
  max-width: 1300px;
  margin-left: auto;
  gap: 20px;
  margin-right: auto;
}

.promotion-stakes-item {
  border-radius: 0.5rem;
  padding: 20px;
  width: 130px;
  /* height: 130px; */
  max-width: 430px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #000000;
  background-image: url("../assets/images/Frame.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  color: white;
  aspect-ratio: 1 / 1;
  flex-basis: 40%;
  text-decoration: none;
  margin-bottom: 20px;
}
.promotion-stakes-item:hover {
  background-image: url("../assets/images/FrameHover.png"),
    linear-gradient(180deg, #ff1a1a 0%, #a00000 100%);
  text-decoration: none;
  color: white;
}
.promotion-stakes-item::after {
  display: flex;
}
.title-text {
  font-size: 0.6875rem; /* 11px*/
  font-weight: 600;

  text-transform: uppercase;
}

.title-points {
  font-size: 20px; /* 20px*/
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
}
.paragraph-text {
  font-size: 0.6875rem; /* 11px*/
  line-height: 1.25rem; /* 20px */
  font-weight: 400;
}
.button-border-radius {
  border-radius: 4px;
}
.button-shadow {
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.2);
}
.flex-basis-45-lg-33 {
  flex-basis: 45%;
}
.flex-basis-40 {
  flex-basis: 40%;
}
.flex-basis-45 {
  flex-basis: 45%;
}
.flex-basis-48 {
  flex-basis: 48%;
}

table {
  background-color: #f8f8f8;
  border-radius: 6px;
  padding-top: 2rem;
  line-height: 2;
  text-align: center;
}

th {
  padding-top: 2rem;
  color: #464646;
  text-transform: uppercase;
}
td {
  color: #707070;
}
tr:last-child td {
  padding-bottom: 2rem;
}
.extra-bold {
  font-weight: 800;
}
.selected-race-button {
  background: #e20303;
  color: #ffffff;
}
.table-divider tr:first-child td {
  margin-top: 1rem;
  padding-top: 1rem;
}
.table-divider tr td:first-child {
  border-right: 2px solid #9a9a9a;

  border-left: 0px;
  border-bottom: 0px;
  border-top: 0px;
}
.table-divider tr {
  padding: 1rem;
}
.table-divider tr:first-child td:first-child {
  border-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    #9a9a9a 50%
  );
}
table th {
  font-size: 12px;
  font-weight: 800;
}
table td {
  font-size: 12px;
  font-weight: 600;
}
.table-divider tr:last-child td:first-child {
  border-image: linear-gradient(
    to bottom,
    #9a9a9a 0%,
    rgba(255, 255, 255, 0) 50%
  );
}

.data-from-backend {
  // overflow: hidden;
}

.data-from-backend div {
  overflow-x: scroll;
  table thead td {
    padding-bottom: 0px;
  }
}
.data-from-backend table tbody tr td {
  width: fit-content;
}

.data-from-backend table tbody tr:first-child {
  td {
    padding-top: 20px;
  }
}
.data-from-backend table tbody tr {
  td {
    padding-right: 10px;
    padding-left: 10px;
  }
  td:first-child {
    padding-left: 20px;
  }
  td:last-child {
    padding-right: 20px;
    border-right: 0px;
  }
}

.data-from-backend table tbody tr:last-child {
  td {
    padding-bottom: 20px;
  }
}

@media screen and (min-width: 576px) {
  .promotion-stakes-item {
    border-radius: 0.5625rem;
  }

  .title-text {
    font-size: 0.9375rem; /* 15px*/
  }
  .title-points {
    font-size: 28px; /* 28px*/
  }
  .paragraph-text {
    font-size: 0.75rem; /* 12px*/
    line-height: 1.4375rem; /* 23px */
  }
  table {
    border-radius: 10px;
  }
  table th {
    font-size: 15px;
    padding-top: 2.6rem;
  }
  table td {
    font-size: 15px;
  }
  tr:last-child td {
    padding-bottom: 2.6rem;
  }
  .data-from-backend table, .data-from-backend th, .data-from-backend td {
    border: 1px solid black;
    border-collapse: collapse;
  }
}

@media screen and (min-width: 768px) {
  .promotion-stakes-item {
    border-radius: 0.625rem;
  }

  .title-text {
    font-size: 1.1875rem; /* 19px*/
  }
  .title-points {
    font-size: 36px; /* 36px*/
  }
  .paragraph-text {
    font-size: 0.875rem; /* 14px*/
    line-height: 1.625rem; /* 26px */
  }
  table {
    border-radius: 14px;
  }
  table th {
    font-size: 18px;
    padding-top: 3.3rem;
  }
  table td {
    font-size: 18px;
  }
  tr:last-child td {
    padding-bottom: 3.3rem;
  }
  .data-from-backend table, .data-from-backend th, .data-from-backend td {
    border: 1px solid black;
    border-collapse: collapse;
  }
}

@media screen and (min-width: 992px) {
  .flex-basis-45-lg-33 {
    flex-basis: 33%;
  }
  .promotion-stakes-item {
    border-radius: 0.75rem;
    flex-basis: 30%;
  }

  .title-text {
    font-size: 1.5rem; /* 24px*/
  }
  .title-points {
    font-size: 44px; /* 44px*/
  }

  .paragraph-text {
    font-size: 0.9375rem; /* 15px*/
    line-height: 1.8125rem; /*29px */
  }
  .button-border-radius {
    border-radius: 6px;
  }
  table {
    border-radius: 18px;
  }
  table th {
    font-size: 21px;
    padding-top: 4rem;
  }
  table td {
    font-size: 21px;
  }
  tr:last-child td {
    padding-bottom: 4rem;
  }
  .data-from-backend table, .data-from-backend th, .data-from-backend td {
    border: 1px solid black;
    border-collapse: collapse;
  }
}

@media screen and (min-width: 1200px) {
  .promotion-stakes-item {
    border-radius: 0.875rem;
  }

  .title-text {
    font-size: 1.75rem; /* 28px*/
  }
  .title-points {
    font-size: 52px; /* 52px*/
  }
  .paragraph-text {
    font-size: 1.125rem; /* 18px*/
    line-height: 2rem; /* 32px */
  }
  table {
    border-radius: 22px;
  }
  table th {
    font-size: 24px;
    padding-top: 4.6rem;
  }
  table td {
    font-size: 24px;
  }
  tr:last-child td {
    padding-bottom: 4.6rem;
  }
  .data-from-backend table, .data-from-backend th, .data-from-backend td {
    border: 1px solid black;
    border-collapse: collapse;
  }
}

@media screen and (min-width: 1400px) {
  .promotion-stakes-item {
    border-radius: 0.9375rem;
  }

  .title-text {
    font-size: 2rem; /* 32px*/
  }
  .title-points {
    font-size: 60px; /* 60px*/
  }

  .paragraph-text {
    font-size: 1.25rem; /* 20px*/
    line-height: 2.1875rem; /* 35px */
  }
  table {
    border-radius: 26px;
  }
  table th {
    font-size: 27px;
    padding-top: 5.4rem;
  }
  table td {
    font-size: 27px;
  }
  tr:last-child td {
    padding-bottom: 5.4rem;
  }
  .data-from-backend table, .data-from-backend th, .data-from-backend td {
    border: 1px solid black;
    border-collapse: collapse;
  }
}

@media screen and (min-width: 1700px) {
  .promotion-stakes-item {
    border-radius: 1rem;
  }
  .title-text {
    font-size: 2.3125rem; /* 37px*/
  }
  .title-points {
    font-size: 60px; /* 70px*/
  }
  .paragraph-text {
    font-size: 1.375rem; /* 22px*/
    line-height: 2.5rem; /* 40px */
  }
  .button-border-radius {
    border-radius: 8px;
  }
  table {
    border-radius: 30px;
  }
  table th {
    font-size: 30px;
    padding-top: 6rem;
  }
  table td {
    font-size: 30px;
  }
  tr:last-child td {
    padding-bottom: 6rem;
  }
  .data-from-backend table, .data-from-backend th, .data-from-backend td {
    border: 1px solid black;
    border-collapse: collapse;
  }
}
