$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;
// Large desktops
$screen-xxl-min: 1400px;
// Extra Large tablets and desktops
$screen-xxxl-min: 1700px;

// Small devices
@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

// Extra Extra large devices
@mixin xxl {
  @media (min-width: #{$screen-xxl-min}) {
    @content;
  }
}

// Extra Extra Extra large devices
@mixin xxxl {
  @media (min-width: #{$screen-xxxl-min}) {
    @content;
  }
}

.leaderboard-image {
  aspect-ratio: 1/1;
  border-radius: 0.5625rem;
  max-width: 21rem;
  @include sm {
    border-radius: 1rem;
    max-width: 25.875rem;
  }
  @include md {
    border-radius: 1.5rem;
    max-width: 30.875rem;
  }
  @include lg {
    border-radius: 1.75rem;
    max-width: 35.75rem;
  }
  @include xl {
    border-radius: 2rem;
    max-width: 41rem;
  }
  @include xxl {
    border-radius: 2.25rem;
    max-width: 45.625rem;
  }
}
.promotion-races .selected-button {
  text-decoration: none;
  background-color: #e20303;
  color: white;

  &:hover {
    background-color: #e20303;
    color: white;
    transform: scale(1.05);
  }
}
.promotion-races .unselected-button {
  background-color: white;
  color: #707070;
  &:hover {
    text-decoration: none;
    transform: scale(1.05);
  }
}

.promotion-back-chevron-button{
    position:absolute;
    left:-40px;
    margin-top:auto;
    margin-bottom: auto;
     font-size: 20px;
    color: #e20303;
    @include sm {
    font-size: 25px;

  }
  @include md {
    font-size: 30px;

  }
  @include lg {
    font-size:35px;

  }
  @include xl {
    font-size: 40px;

  }
  @include xxl {
    font-size: 45px;

  }
  @include xxxl {
      top:-5px;
    font-size: 50px;

  }
}

.leaderboard-name {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 20px;
  text-transform: uppercase;

  @include sm {
    font-size: 1.6875rem;
    line-height: 2.625rem;
  }
  @include md {
    font-size: 2.0625rem;
    line-height: 3.25rem;
  }
  @include lg {
    font-size: 2.5rem;
    line-height: 3.875rem;
  }
  @include xl {
    font-size: 2.875rem;
    line-height: 4.5rem;
  }
  @include xxl {
    font-size: 3.3125rem;
    line-height: 5.125rem;
  }
  @include xxxl {
    font-size: 3.75rem;
    line-height: 5.75rem;
  }
}
.leadearboard-title {
  font-size: 1.875rem;
  font-weight: 700;
  line-height: 32px;
  text-transform: uppercase;
  @include sm {
    font-size: 2.1875rem;
    line-height: 2.625rem;
  }
  @include md {
    font-size: 2.8125rem;
    line-height: 3.25rem;
  }
  @include lg {
    font-size: 3.4375rem;
    line-height: 3.875rem;
  }
  @include xl {
    font-size: 4.0625rem;
    line-height: 4.5rem;
  }
  @include xxl {
    font-size: 4.6875rem;
    line-height: 5.125rem;
  }
  @include xxxl {
    font-size: 5.625rem;
    line-height: 5.75rem;
  }
}
.margin-top-leaderboard-section {
  margin-top: 1.5625rem;
  @include sm {
    margin-top: 2.625rem;
  }
  @include md {
    margin-top: 3.5625rem;
  }
  @include lg {
    margin-top: 4.625rem;
  }
  @include xl {
    margin-top: 5.6875rem;
  }
  @include xxl {
    margin-top: 6.75rem;
  }
  @include xxxl {
    margin-top: 8.125rem;
  }
}
.table-margin-top {
  margin-top: 35px;

  @include sm {
    margin-top: 3.4375rem;
  }
  @include md {
    margin-top: 4.4375rem;
  }
  @include lg {
    margin-top: 5.4375rem;
  }
  @include xl {
    margin-top: 6.4375rem;
  }
  @include xxl {
    margin-top: 7.4375rem;
  }
  @include xxxl {
    margin-top: 8.4375rem;
  }
}
.date-button-with-background {
  background-image: url("../../assets/images/date-button-background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 540/121;
  max-width: 540px;
  color: white;
}
.carousel-button {
  background-color: #e7e6e6;
  color: black;
  padding: 1rem 2rem;
  border-radius: 6px;
  font-weight: 700;
  font-size: 12px;
  @include sm {
    font-size: 15px;
  }
  @include md {
    font-size: 18px;
  }
  @include lg {
    font-size: 21px;
  }
  @include xl {
    font-size: 24px;
  }
  @include xxl {
    font-size: 27px;
  }
  @include xxxl {
    font-size: 30px;
  }
}
.selected-carousel-button {
  background-color: #e20303;
  color: white;
  &:hover {
    background-color: #e20303;
    color: white;
  }
}
