.promotions-banner {
  background-image: url("../assets/images/promotionsBanner.png");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  /* margin-left: 1.25rem; 
    margin-right: 1.25rem; */
  height: 5.375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.3125rem;
}
.promotions-heading {
  font-family: "Poppins";
  font-style: normal;
  color: white;
  font-weight: bold;
  font-size: 0.9375rem;
  text-transform: uppercase;
  letter-spacing: 0.38em;
  line-height: 4.625rem;
}
.promotion-type-selector {
  display: flex;
  justify-content: center;
}
.promotion-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.6875rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 1.9375rem;
  color: #707070;
  width: 9.25rem;
  height: 2.6875rem;
  background: #ffffff;
  box-shadow: 0rem 0.125rem 0.25rem rgba(0, 0, 0, 0.18);
  border-radius: 0.25rem;
  text-align: center;
  &:hover {
    transform: scale(1.05);
  }
}

.selected-promotion-button {
  background: #e20303;
  color: #ffffff;
}
.button-margin {
  margin-right: 0.625rem;
}

.promotion-items-container {
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: center;
}

.promotion-item {
  border-radius: 10px;
  padding: 0;
  overflow: hidden;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.2);
  margin-top: 1.4375rem;
}
.promotion-image {
  width: 100%;
  object-fit: contain;
}
.promotion-name {
  font-size: 11px;
  font-weight: 500;
  margin-bottom: 0;
  text-transform: uppercase;
}
.promotion-link {
  color: #e20303;
  font-size: 11px;
  font-weight: 500;
  text-decoration: none;
  white-space: nowrap;
  &:hover {
    text-decoration: none;
  }
}

.deposit-contents{
  tr:last-child td{
    padding-bottom: 0;
  }
}

.clickbtn{
  font-size: 11px;
  background: transparent;
  border: none;
  &::after{
    content: ' >>>';
  }
}
.customText{
  margin-left: 10rem;
}
.previous-promotions-container {
  margin-top: 2rem;
  max-width: 1180px;
  background: #ffffff;
  box-shadow: 0rem 0.125rem 0.25rem rgba(0, 0, 0, 0.18);
  border-radius: 0.25rem;
}
.margin-top-after-banner {
  margin-top: 2rem;
}
.previous-promotions-title {
  font-size: 0.6875rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 0;
}
.previous-promotions-image {
  width: 1rem;
  min-height: 20px;
  background-image: url("../assets/images/downArrowWithColor.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.selected-previous-promotions {
  background-color: #e20303;
  color: white;
}

.selected-previous-promotions p {
  color: white;
}

.selected-previous-promotions .previous-promotions-image {
  background-image: url("../assets/images/upArrow.svg");
}

@media screen and (min-width: 576px) {
  .promotions-banner {
    height: 8.375rem;
    border-radius: 0.5625rem;
  }
  .promotions-heading {
    font-size: 1.3125rem;
  }
  .promotion-button {
    font-size: 0.9375rem;

    margin-top: 2.75rem;

    width: 14.5rem;
    height: 3.6875rem;
    box-shadow: 0rem 0.125rem 0.25rem rgba(0, 0, 0, 0.18);
    border-radius: 0.4375rem;
    text-align: center;
  }
  .button-margin {
    margin-right: 1.1875rem;
  }
  .promotion-item {
    border-radius: 12px;
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.2);
    margin-top: 2.5rem;
  }
  .promotion-name {
    font-size: 0.875rem;
  }
  .promotion-link {
    font-size: 0.875rem;
  }
  .previous-promotions-title {
    font-size: 0.9375rem;
  }
  .previous-promotions-container {
    margin-top: 2.875rem;
    border-radius: 0.4375rem;
  }
  .margin-top-after-banner {
    margin-top: 2.875rem;
  }
  .previous-promotions-image {
    width: 1.375rem;
  }
}

@media screen and (min-width: 768px) {
  .promotions-banner {
    height: 11.375rem;
    border-radius: 0.8125rem;
  }
  .promotions-heading {
    font-size: 1.6875rem;
  }
  .promotion-button {
    font-size: 1.25rem;
    margin-top: 3.6875rem;
    width: 19.625rem;
    height: 4.6875rem;
    box-shadow: 0rem 0.125rem 0.25rem rgba(0, 0, 0, 0.18);
    border-radius: 0.625rem;
    text-align: center;
  }
  .button-margin {
    margin-right: 1.75rem;
  }
  .promotion-item {
    border-radius: 12px;
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.2);
    margin-top: 3.4375rem;
  }
  .promotion-name {
    font-size: 1rem;
  }
  .promotion-link {
    font-size: 1rem;
  }
  .previous-promotions-container {
    margin-top: 3.75rem;
    border-radius: 0.625rem;
  }
  .margin-top-after-banner {
    margin-top: 3.75rem;
  }
  .previous-promotions-title {
    font-size: 1.25rem;
  }
  .previous-promotions-image {
    width: 1.75rem;
  }
}

@media screen and (min-width: 992px) {
  .promotions-banner {
    height: 24.75rem;
    border-radius: 1.0625rem;
  }
  .promotions-heading {
    font-size: 2.0625rem;
  }
  .promotion-button {
    font-size: 1.5rem;

    margin-top: 4.5625rem;

    width: 24.625rem;
    height: 5.6875rem;
    box-shadow: 0rem 0.125rem 0.25rem rgba(0, 0, 0, 0.18);
    border-radius: 0.8125rem;
    text-align: center;
  }
  .button-margin {
    margin-right: 2.3125rem;
  }
  .promotion-item {
    border-radius: 14px;
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.2);
    margin-top: 4.375rem;
  }

  .promotion-name {
    font-size: 1.125rem;
  }
  .promotion-link {
    font-size: 1.125rem;
  }
  .previous-promotions-container {
    margin-top: 4.5rem;
    border-radius: 0.8125rem;
  }
  .margin-top-after-banner {
    margin-top: 4.5rem;
  }
  .previous-promotions-title {
    font-size: 1.5rem;
  }

  .previous-promotions-image {
    width: 2.125rem;
    background-image: url("../assets/images/downArrow.svg");
  }
  .previous-promotions {
    background-color: #e20303;
  }
  .selected-previous-promotions {
    background-color: white;
    color: #707070;
    padding-bottom: 3.125rem !important;
  }
  .previous-promotions p {
    color: white;
  }

  .selected-previous-promotions p {
    color: #707070;
  }
  .selected-previous-promotions .previous-promotions-image {
    background-image: url("../assets/images/upArrowWithColor.svg");
  }
}

@media screen and (min-width: 1200px) {
  .promotions-banner {
    height: 17.375rem;
    border-radius: 1.3125rem;
  }
  .promotions-heading {
    font-size: 2.4375rem;
  }
  .promotion-button {
    font-size: 1.8125rem;

    margin-top: 5.3125rem;

    width: 30.25rem;
    height: 6.6875rem;
    box-shadow: 0rem 0.125rem 0.25rem rgba(0, 0, 0, 0.18);
    border-radius: 1rem;
    text-align: center;
  }
  .button-margin {
    margin-right: 2.875rem;
  }
  .promotion-item {
    border-radius: 16px;
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.2);
    margin-top: 5.3125rem;
  }
  .promotion-name {
    font-size: 1.25rem;
  }
  .promotion-link {
    font-size: 1.25rem;
  }
  .previous-promotions-container {
    margin-top: 5.3125rem;
    border-radius: 1rem;
  }
  .margin-top-after-banner {
    margin-top: 5.3125rem;
  }
  .previous-promotions-title {
    font-size: 1.8125rem;
  }
  .previous-promotions-image {
    width: 2.5rem;
  }
}

@media screen and (min-width: 1400px) {
  .promotions-banner {
    height: 20.25rem;
    border-radius: 1.625rem;
  }
  .promotions-heading {
    font-size: 3.0625rem;
  }
  .promotion-button {
    font-size: 2rem;
    margin-top: 6.1875rem;
    width: 35.375rem;
    height: 7.6875rem;
    box-shadow: 0rem 0.125rem 0.25rem rgba(0, 0, 0, 0.18);
    border-radius: 1.125rem;
    text-align: center;
  }
  .button-margin {
    margin-right: 3.4375rem;
  }

  .promotion-item {
    border-radius: 18px;
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.2);
    margin-top: 6.25rem;
  }
  .promotion-name {
    font-size: 1.375rem;
  }
  .promotion-link {
    font-size: 1.375rem;
  }
  .previous-promotions-container {
    margin-top: 6.25rem;
    border-radius: 1rem;
  }
  .margin-top-after-banner {
    margin-top: 6.5rem;
  }
  .previous-promotions-title {
    font-size: 2.1875rem;
  }
  .previous-promotions-image {
    width: 2.875rem;
  }
}

@media screen and (min-width: 1700px) {
  .promotion-name {
    font-size: 1.5625rem;
  }
  .promotion-link {
    font-size: 1.5625rem;
  }
  .previous-promotions-image {
    width: 2.875rem;
  }
}

/* @media screen and (min-width:576px) {
    
}

@media screen and (min-width:768px) {
    
}

@media screen and (min-width:992px) {
    
}

@media screen and (min-width:1200px) {
   
}

@media screen and (min-width:1400px) {

} */
