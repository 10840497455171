.header {
  margin-top: 2.1875rem;
  margin-bottom: 2.1875rem;
  padding-right: 0.625rem;
}
.navbar-brand {
  padding-left: 0px;
  margin-bottom: 0.9375rem;
}
.navbar-header-li {
  text-decoration: none;
}

.navbar-header-li .link {
  text-decoration: none;

  color: #707070;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 1.625rem;
  margin-right: 3.75rem;
  font-family: "Poppins";
  font-style: normal;
  &:hover {
    color: #e20303;
  }
}
/* .header a:hover{
    text-decoration: none;
    color: blue;
} */
.navbar-header-li-button {
  font-size: 1.625rem;
  font-weight: 600;
  padding: 0.625rem 1.875rem;
  text-decoration: none;
  border-radius: 0.3125rem;
  color: #fff;
  background-color: #e20303;
  border-color: #e20303;
  box-shadow: 0 0.125rem 0.25rem rgb(176 176 176) !important;
  &:hover {
    background-color: #e20303;
    transform: scale(1.05);
    color: white;
    text-decoration: none;
  }
}

.header--sidebar-button {
  font-size: 1.625rem;
  font-weight: 600;
  padding: 0.625rem 1.875rem;
  text-decoration: none;
  border-radius: 0.3125rem;
  color: #fff;
  border: none;
  background-color: #e20303;
  border-color: #e20303;
  // box-shadow: 0.4rem 0.25rem 0.25rem rgb(176 176 176);
  &:hover {
    background-color: #e20303;
    transform: scale(1.05);
    color: white;
    text-decoration: none;
  }
}
.button-margin-right {
  margin-right: 3.75rem;
}

.uppercase {
  text-transform: uppercase;
}

.z-20 {
  z-index: 20;
}
.z-1000 {
  z-index: 1000;
}
.header-offers {
  position: relative;
  .header-dropdown {
    display: none;
    position: absolute;
    // margin-top: 1rem;
    width: max-content;
    z-index: 10;

    left: -90px;
    .dropdown-background {
      background-color: white;
      box-shadow: 0 4px 0px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
      padding: 3rem 1rem;
      margin-top: 40px;
      width: max-content;
      display: flex;
      z-index: 15;
      justify-content: center;
      border-radius: 8px;
      box-shadow: 2px 2px 4px 4px rgb(0 0 0 / 0.4);
    }
    &:hover {
      display: block;
    }
  }

  &:hover {
    .header-dropdown {
      display: inline-block;
    }

    &:after {
      position: absolute;
      content: "";
      width: 15px;
      height: 15px;
      background-color: white;
      // border-right: 15px solid transparent;
      // border-left: 15px solid transparent;
      // border-bottom: 15px solid #f6f6f6;

      // border-color: transparent transparent #fff #fff;
      box-sizing: border-box;
      top: 40px;
      z-index: 26;
      left: 10px;
      transform-origin: 0 0;
      transform: rotate(-45deg);
      box-shadow: 1px -1px 1px 0px rgb(0 0 0 / 0.4);
    }
  }
}
@media screen and (min-width: 576px) {
}

@media screen and (min-width: 768px) {
}

@media screen and (min-width: 992px) {
  .navbar-header-li .link {
    font-size: 1.125rem;
    margin-right: 1.125rem;
  }
  .navbar-header-li a.link.uppercase{
    text-align: center;
  }
  .button-margin-right {
    margin-right: 0.625rem;
  }
  .navbar-header-li-button {
    font-size: 1.125rem;
  }
}

@media screen and (min-width: 1200px) {
  .navbar-header-li .link {
    font-size: 1.4375rem;
    margin-right: 2.5rem;
  }
  .navbar-header-li a.link.uppercase{
    margin-right: 1.5rem;
    text-align: center;
  }
  .button-margin-right {
    margin-right: 1.25rem;
  }
  .navbar-header-li-button {
    font-size: 1.4375rem;
  }
}

@media screen and (min-width: 1400px) {
  .navbar-header-li .link {
    font-size: 1.625rem;
    margin-right: 3.75rem;
  }
  .navbar-header-li-button {
    font-size: 1.625rem;
  }
}
