$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;
// Large desktops
$screen-xxl-min: 1400px;
// Extra Large tablets and desktops
$screen-xxxl-min: 1700px;

// Small devices
@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

// Extra Extra large devices
@mixin xxl {
  @media (min-width: #{$screen-xxl-min}) {
    @content;
  }
}

// Extra Extra Extra large devices
@mixin xxxl {
  @media (min-width: #{$screen-xxxl-min}) {
    @content;
  }
}

/* .Container css */
._1Lxpd {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 0;
}
/* .Container span */
._1Lxpd span {
  /* z-index: 1000;
  cursor: pointer; */
  display: none;
}

/* .cardCarousel */
._3tZKZ {
  list-style: none;
  padding: 0;
  position: relative;
}

/* .carouselDefault */

._215Bn {
  width: 250px;
  height: 200px;
  margin: 20px;
}
/* .leftButton */
._2ocTZ {
  color: cornflowerblue;
  font-size: 2em;
  padding: 5px;
}
/* .rightButton */
._2YVEG {
  color: cornflowerblue;
  font-size: 2em;
  padding: 5px;
}
/* .cardSummary */
._3kMpo {
  width: 50%;
  height: 50%;
  box-shadow: 0 10px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease-in-out;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(80px, 1fr));
  grid-gap: 0px;
}
/* .cardSummary div */
._3kMpo div {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  transition: all 0.5s ease-in-out;
  /* opacity: 0; 
    position: absolute;
    transform: scale(1) translateX(40px); */
}
/* .cardSummary img */
._3kMpo img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  overflow: hidden;
}
/* .card */
._xsi02 {
  width: 200px;
  height: 200px;
  box-shadow: 0 10px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease-in-out;
  opacity: 0;
  position: absolute;
  overflow: hidden;
  transform: scale(1) translateX(40px);
}

/* .card  img*/

._xsi02 img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

/* .card .active*/

._xsi02._3sodH {
  opacity: 1;
  transform: scale(1) translateY(0);
  z-index: 3;
}

/* .card.next */
._xsi02._1uQwZ {
  opacity: 0.6;
  z-index: 2;
  transform: scale(0.8) translateX(60px);
}
/* .card.prev */
._xsi02._KqFRp {
  transform: scale(0.8) translateX(-60px);
  z-index: 1;
  opacity: 0.6;
}
/* .card.inactive */
._xsi02._3hvs3 {
  transform: scale(1) translateX(0px);
  z-index: 1;
  opacity: 0;
}
/* .card.noTranslate */
._xsi02._1HDdD {
  transform: scale(1) translateX(0px);
  z-index: 1;
  opacity: 0;
}
.stacked-carousel-image-size {
  width: 200px;
  height: 200px;
  @include sm {
    width: 300px;
    height: 300px;
  }
  @include md {
    width: 400px;
    height: 400px;
  }
  @include lg {
    width: 600px;
    height: 247px;
  }
}
@media only screen and (min-width: 576px) {
  ._xsi02 {
    width: 300px;
    height: 300px;
    transform: scale(1) translateX(40px);
  }
  /* .card.next */
  ._xsi02._1uQwZ {
    opacity: 0.6;
    z-index: 2;
    transform: scale(0.8) translateX(120px);
  }
  /* .card.prev */
  ._xsi02._KqFRp {
    transform: scale(0.8) translateX(-120px);
    z-index: 1;
    opacity: 0.6;
  }
  /* .card.inactive */
}

@media only screen and (min-width: 768px) {
  ._xsi02 {
    width: 400px;
    height: 400px;
    transform: scale(1) translateX(40px);
  }
}

@media only screen and (min-width: 992px) {
  ._xsi02 {
    width: 600px;
    height: 274px;
    transform: scale(1) translateX(40px);
  }
  ._1Lxpd span {
    display: block;
    z-index: 500;
    cursor: pointer;
  }
}

@media only screen and (min-width: 1200px) {
  ._xsi02 {
    width: 600px;
    height: 274px;
    transform: scale(1) translateX(40px);
  }
}
